import { Result, Card, Grid } from "antd-mobile";
import React, { useEffect, useState } from "react";
import axios from "axios";
export default function Index() {
    const [data, setData] = useState({ android: [], ios: [] });
    const initData = async () => {
        const body = {
            action: 2,
            version: "1.0",
            sign: "",
            time: "",
            parameters: {}
        }
        axios.create({
            headers: {
                'content-type': 'application/json',
                'Cache-Control': 'no-cache'
            }
        });
        const res = await axios.post('http://47.108.63.75:41712/api/App', body, {});
        if (res.data.code === 0) {
            setData(res.data.data);
            return;
        }
        setData({ android: [], ios: [] });
        return;
    }
    const onAppClick = (item) => {
        window.location.href = item.url;
    }
    useEffect(() => {
        const timeout = setTimeout(() =>
            initData(), 10);
        return () => clearTimeout(timeout);
    }, [])
    return (<div style={{ padding: 0 }}>
        <Card style={{ backgroundColor: '#fffbe6', borderRadius: 0, border: '0px solid #ccc' }}>
            注：如果您是在微信中打开此页面，请点击右上角“...”按钮，选择“在浏览器中打开”，然后点击相应app进行下载
        </Card>
        {
            data.android.length > 0
                ? <Card title='安卓应用'>
                    <Grid columns={3} style={{ borderLeft: '1px solid #eee',borderTop: '1px solid #eee' }}>
                        {
                            data.android.map(item => <Grid.Item key={item.name} style={{ textAlign: 'center', borderRight: '1px solid #eee', borderBottom: '1px solid #eee', margin: 0 }}>
                                <Card style={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} onClick={() => { onAppClick(item) }}>
                                    <div style={{ width: '100%', textAlign: 'center', height: '48px', backgroundImage: 'url(' + item.logo + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: '48px 48px' }}>
                                    </div>
                                    <div style={{ textAlign: 'center', lineHeight: '30px' }}>{item.name}</div>
                                </Card>
                            </Grid.Item>
                            )
                        }
                    </Grid>
                </Card>
                : <Result
                    status='info'
                    title='暂未开放'
                    description={'该功能暂未开放'}
                />
        }
        {
            data.ios.length > 0
                ? <Card title='苹果应用'>
                    <Grid columns={3} style={{ borderLeft: '1px solid #eee',borderTop: '1px solid #eee' }}>
                        {
                            data.ios.map(item => <Grid.Item key={item.name} style={{ textAlign: 'center', borderRight: '1px solid #eee', borderBottom: '1px solid #eee', margin: 0 }}>
                                <Card style={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} onClick={() => { onAppClick(item) }}>
                                    <div style={{ width: '100%', textAlign: 'center', height: '48px', backgroundImage: 'url(' + item.logo + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: '48px 48px' }}>
                                    </div>
                                    <div style={{ textAlign: 'center', lineHeight: '30px' }}>{item.name}</div>
                                </Card>
                            </Grid.Item>
                            )
                        }
                    </Grid>
                </Card>
                : null
        }
        <div style={{ width: '100%', color: '#999', textAlign: 'center' }}>贵州金宏鑫科技有限公司 © {new Date().getFullYear()}<br />黔ICP备14003456号-1 </div>
    </div>)
}